export const constants = {

    api: {
        parceiro: 'NET SÃO JOSE',
    },

    texts: {
        nomeEmpresa: 'NET MÓVEL',
    },

    links: {
        linkPedirChip: 'https://wa.me/551235707000?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site',
        linkApp: 'https://infiniti.app/provedores/aplicativos/netsaojoseapp',
        linkWpp: 'https://api.whatsapp.com/send/?phone=551235707000&text&type=phone_number&app_absent=0',
        linkMapaCobertura: 'https://tim.img.com.br/mapa-cobertura/',
        linkTenhaSuaOperadora: null,
        // linkTenhaSuaOperadora: 'https://api.whatsapp.com/send?phone=5561920040101&text=quero%20saber%20mais', // INFINITI Apenas
        linkRastrearMeuPedido: 'https://tracking.totalexpress.com.br/',
        linkPoliticaDePrivacidade: 'https://privacidade.operadora.app.br/#/saojose',
        linkInstagram: 'https://www.instagram.com/netsaojosevp',
    },

}